import React, {useState} from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Button from 'reusecore/src/elements/Button';
import Container from 'common/src/components/UI/Container';

import PricingTable, {
   PricingHead,
   PricingPrice,
   PricingButton,
   PricingList,
   ListItem,
   PricingButtonWrapper,
} from './pricing.style';

import {checkmark} from 'react-icons-kit/icomoon/checkmark';
import {plus} from 'react-icons-kit/icomoon/plus';
import ListGrid from 'reusecore/src/elements/ListGrid';
import Card from 'reusecore/src/elements/Card';
import PaddleButton from './PaddleButton';

const PricingSection = ({
                           sectionWrapper,
                           row,
                           secTitleWrapper,
                           secHeading,
                           secText,
                           nameStyle,
                           descriptionStyle,
                           priceStyle,
                           salePriceStyle,
                           priceIntervalStyle,
                           priceLabelStyle,
                           buttonStyle,
                           buttonFillStyle,
                           listContentStyle,
                           showTitle,
                           showLite,
                           paddleAffiliateId
                        }) => {
   const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        MONTHLY_PRICING_TABLE {
          name
          productId
          description
          price
          salePrice
          priceLabel
          buttonLabel
          url
          listItems {
            content
            bold
            plus
            url
          }
        }
        YEARLY_PRICING_TABLE {
          name
          productId
          description
          price
          salePrice
          priceLabel
          buttonLabel
          url
          listItems {
            content
            bold
            plus
            url
          }
        }
      }
    }
  `);

   const [state, setState] = useState({
      data: Data.saasModernJson.YEARLY_PRICING_TABLE,
      interval: '/year',
      active: true,
   });

   const data = showLite
      ? state.data
      : state.data.filter(x => x.name !== 'Lite');
   const interval = state.interval;
   const activeStatus = state.active;

   const onSale = (paddleAffiliateId === "12386");

   const saleInfo = onSale ?
   (<h2 style={{textAlign: 'center', margin: '48px 0 0 0', color: "#FF2819"}}><strong>PrintableCal is on sale - BitsDuJour customers get a 50% discount!</strong></h2>)
   : null;

   const products = data.map((pricingTable, index) => (
      <Card key={`pricing-table-${index}`} style={{height: '100%'}}>
      <PricingTable className="pricing_table">
         <PricingHead>
            <Heading
            content={pricingTable.name}
            {...nameStyle}
            style={{textAlign: 'center', marginBottom: 12, fontSize: 22}}
            />
         </PricingHead>
         <PricingPrice>
            {onSale && pricingTable.productId ?
               (<>
                  <p style={{marginTop: 0, marginBottom: 12}}>
                     <Text content={pricingTable.price} {...priceStyle} style={{textDecoration: 'line-through'}} />
                     <Text content={interval} {...priceIntervalStyle} />
                  </p>
                  <p style={{marginTop: 0, marginBottom: 12}}>
                     <Text content={pricingTable.salePrice} {...salePriceStyle} />
                     <Text content={interval} {...priceIntervalStyle} />
                  </p>
               </>) :
               (<>
                  <Text content={pricingTable.price} {...priceStyle} />
                  {pricingTable.productId ? (
                     <Text content={interval} {...priceIntervalStyle} />
                     ) : null}
                  <Text content={pricingTable.description} {...descriptionStyle} style={{marginBottom: onSale ? 54.5 : 0}} />
               </>)
            }
         </PricingPrice>
         <PricingButton>
            {pricingTable.productId ? (
            <PaddleButton
            buttonLabel={pricingTable.buttonLabel}
            buttonFillStyle={buttonFillStyle}
            onSale={onSale}
            productId={pricingTable.productId}
            style={{
               borderRadius: 22,
               backgroundColor: '#19478A',
               color: '#fff',
            }}
            />
            ) : (
            <Link to={pricingTable.url}>
               <Button
               isMaterial={true}
               title={pricingTable.buttonLabel}
               {...buttonStyle}
               style={{
                  borderRadius: 22
               }}
               />
            </Link>
            )}
         </PricingButton>
         <Text content={pricingTable.priceLabel} {...priceLabelStyle} />
         <PricingList>
            {pricingTable.listItems.map((item, index) => (
            <ListItem key={`pricing-table-list-${index}`}>
               {item.plus ? (
               <Icon icon={plus} className="price_list_icon_plus" size={13}/>
               ) : (
               <Icon
               icon={checkmark}
               className="price_list_icon_checkmark"
               size={13}
               />
               )}
               <Text
               content={
                  item.bold ? <strong>{item.content}</strong> : item.url ? (
                  <Link className={item.plus ? "pricing_feature_link_plus" : "pricing_feature_link"}
                        to={item.url}>{item.content}</Link>) : item.content
               }
               style={{marginBottom: 0, fontSize: 16}}
               {...listContentStyle}
               />
            </ListItem>
            ))}
         </PricingList>
      </PricingTable>
   </Card>
   ));

   const planTypeContent = (
   <div style={{textAlign: 'center'}}>
      <p style={{fontSize: 14}}>
         Prices are in USD before any applicable taxes. Cancel any time.
      </p>
   </div>
   );

   const planComparisonContent = (
   <Box {...row}>
      <ListGrid
      data={showLite ? [0, 1, 2, 3, 4] : [0, 1, 2, 3]}
      columnWidth={
         showLite ? [1, 1, 1 / 2, 1 / 2, 1 / 5] : [1, 1, 1, 1 / 4, 1 / 4]
      }
      component={index => products[index]}
      />
   </Box>
   );

   return showTitle ? (
   <Box {...sectionWrapper} id="pricing_section">
      <Container>
         <Box {...secTitleWrapper}>
            <Text {...secText} content="PRICING"/>
            <Heading
            {...secHeading}
            content="PrintableCal is a free calendar maker with advanced paid features."
            />
            {saleInfo}
            {planTypeContent}
         </Box>
         {planComparisonContent}
      </Container>
   </Box>
   ) : (
   <Container>
      {saleInfo}
      <div style={{marginBottom: 32}}>{planTypeContent}</div>
      {planComparisonContent}
   </Container>
   );
};

PricingSection.propTypes = {
   sectionWrapper: PropTypes.object,
   row: PropTypes.object,
   secTitleWrapper: PropTypes.object,
   secHeading: PropTypes.object,
   secText: PropTypes.object,
   nameStyle: PropTypes.object,
   descriptionStyle: PropTypes.object,
   priceStyle: PropTypes.object,
   salePriceStyle: PropTypes.object,
   priceIntervalStyle: PropTypes.object,
   priceLabelStyle: PropTypes.object,
   listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
   showTitle: true,
   showLite: true,
   sectionWrapper: {
      as: 'section',
      pt: 100,
      pb: 0,
   },
   row: {
      flexBox: true,
      flexWrap: 'wrap',
      ml: '-15px',
      mr: '-15px',
      alignItems: 'center',
   },
   secTitleWrapper: {
      mb: '32px',
   },
   secText: {
      as: 'span',
      display: 'block',
      textAlign: 'center',
      fontSize: '14px',
      letterSpacing: '0.15em',
      fontWeight: '700',
      color: '#007C3E',
      mb: '5px',
   },
   secHeading: {
      fontSize: ['32px', '32px', '40px', '42px', '46px'],
      fontFamily: 'Google Sans',
      fontWeight: '400',
      letterSpacing: '-0.5px',
      lineHeight: '1.17857',
      textAlign: 'center',
      color: '#0f2137',
      mb: '0',
   },
   col: {
      width: [1, 1 / 2, 1 / 2, 1 / 3],
      pr: '15px',
      pl: '15px'
   },
   nameStyle: {
      fontSize: ['20px', '20px', '22px', '22px', '22px'],
      fontWeight: '500',
      color: 'headingColor',
      letterSpacing: '-0.025em',
      textAlign: 'center',
      mb: '12px',
   },
   descriptionStyle: {
      fontSize: ['15px', '16px', '16px', '16px', '16px'],
      color: 'textColor',
      lineHeight: '1.6',
      textAlign: 'center',
      mb: '0',
      minHeight: '28px',
   },
   priceStyle: {
      as: 'span',
      display: 'inline',
      fontSize: ['36px', '36px', '40px', '40px', '40px'],
      color: 'headingColor',
      textAlign: 'center',
      mb: '5px',
      letterSpacing: '-0.025em',
   },
   salePriceStyle: {
      as: 'span',
      display: 'inline',
      fontSize: ['36px', '36px', '40px', '40px', '40px'],
      fontWeight: 'bold',
      color: '#FF2819',
      textAlign: 'center',
      mb: '5px',
      letterSpacing: '-0.025em',
   },
   priceIntervalStyle: {
      as: 'span',
      display: 'inline',
      fontSize: '13px',
      color: 'headingColor',
      textAlign: 'center',
      mb: '5px',
      ml: '4px',
      letterSpacing: '-0.025em',
   },
   priceLabelStyle: {
      fontSize: ['13px', '14px', '14px', '14px', '14px'],
      color: 'textColor',
      lineHeight: '1.6',
      textAlign: 'center',
      minHeight: ['0', '0', '96px', '80px', '128px'],
   },
   buttonStyle: {
      type: 'button',
      fontSize: '18px',
      fontWeight: '600',
      height: '32px',
      borderRadius: '22px',
      borderWidth: '0',
      pl: '10px',
      pr: '10px',
      colors: 'secondaryWithBg',
      width: '200px',
      maxWidth: '100%',
   },
   buttonFillStyle: {
      type: 'button',
      fontSize: '18px',
      fontWeight: '600',
      color: 'white',
      height: '32px',
      borderRadius: '22px',
      borderWidth: '0',
      pl: '10px',
      pr: '10px',
      colors: 'primaryWithBg',
      width: '200px',
      maxWidth: '100%',
      boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
   },
   listContentStyle: {
      fontSize: ['15px', '16px', '16px', '16px', '16px'],
      color: 'textColor',
      mb: '0',
   },
};

export default PricingSection;

import React, { Fragment } from 'react';
import Container from 'common/src/components/UI/Container';
import SEO from '../../../components/seo';
import { ResetCSS } from 'common/src/assets/css/style';
import { ContentWrapper, GlobalStyle } from '../sassModern.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../Download/Navbar';
import Footer from '../Footer';
import SimplePageWrapper from './simple-page.style';
import Box from 'reusecore/src/elements/Box';
import Layout from '../../../components/layout';

const SimplePageSection = ({ title, description, keywords, content }) => {
  return (
    <Layout>
      <Fragment>
        <SEO title={title} description={description} keywords={keywords} />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Box as="section">
            <Container>
              <SimplePageWrapper>
                <div style={{ marginBottom: 40 }}>{content}</div>
              </SimplePageWrapper>
            </Container>
            <Footer />
          </Box>
        </ContentWrapper>
      </Fragment>
    </Layout>
  );
};

export default SimplePageSection;

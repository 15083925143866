import styled from 'styled-components';

const PricingTable = styled.div`
  border: 1px solid #f2f4f7;
  border-radius: 5px;
  border-radius: 5px;
  padding: 24px 24px 32px 24px;
  height: 100%;
`;

const PricingHead = styled.div`
  margin-bottom: 20px;
  h2 {
    margin-top: 0;
    padding-top: 0;
  }
`;

const PricingPrice = styled.div`
  margin-top: 40px;
  margin-bottom: 30px;
  text-align: center;
  line-height: 30px;
`;

const PricingButton = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

const PricingList = styled.div``;

const ListItem = styled.div`
  display: flex;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  .price_list_icon_checkmark {
    color: #19478a;
    margin-right: 10px;
  }
  .price_list_icon_plus {
    color: #18d379;
    margin-right: 10px;
  }
  .pricing_feature_link {
    color: #43414e
  }
  .pricing_feature_link_plus {
    color: #007C3E
  }
  .pricing_feature_link:hover {
    text-decoration: underline
  }
  .pricing_feature_link_plus:hover {
    text-decoration: underline
  }
`;

const PricingButtonWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
  display: inline-flex;
  .reusecore__button {
    font-size: 20px;
    font-weight: 400;
    color: #000;
    background: #fff;
    height: 80px;
    width: 200px;
    border: 1px solid #e4e9ee;
    &:nth-child(1) {
      border-top-left-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 5px;
      border-right-color: transparent;
    }
    &:nth-child(2) {
      border-top-left-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 0;
      border-left-color: transparent;
    }
    &.active-item {
      color: white;
      border-width: 0;
      background-color: #007c3e;
    }
    @media (max-width: 575px) {
      font-size: 14px;
      height: 54px;
      width: 120px;
      padding: 0 5px;
    }
  }
`;

export {
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  PricingButtonWrapper,
};
export default PricingTable;

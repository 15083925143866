/*global trackConversion*/
import React from 'react';
import Button from 'reusecore/src/elements/Button';

export default class PaddleButton extends React.Component {
   state = {
      paddle: null,
   };

   componentDidMount() {
      const paddle = window.Paddle;

      if (paddle) {
         this.setState({paddle: paddle});
      }

      /* This works, but IPv6
      fetch('https://ipapi.co/json/')
      .then(response => {
         console.log(response);
         if (response.ok) {
            return response.json();
         } else {
            throw new Error("Error accessing IP address.");
         }
      })
      .then(data => {
         console.log(data);
      })
      */


   }

   showSuccess = data => {
      const {paddle} = this.state;
      trackConversion(data);
      paddle.Order.DetailsPopup(data.checkout.id);
   };

   render() {
      const {productId, buttonLabel, buttonFillStyle, onSale} = this.props;

      return (
         <React.Fragment>
            <Button
               title={buttonLabel}
               {...buttonFillStyle}
               style={{borderRadius: 22, borderWidth: 0}}
               onClick={() => {
                  const {paddle} = this.state;

                  if (paddle) {
                     fetch('https://api.ipify.org/?format=json')
                        .then(response => {
                           if (response.ok) {
                              return response.json();
                           } else {
                              throw new Error("Error accessing IP address.");
                           }
                        })
                        .then(data => {
                           paddle.Checkout.open({
                              successCallback: this.showSuccess,
                              coupon: onSale ? 'BDJ2020' : null,
                              passthrough: data.ip,
                              product: productId,
                           });
                        })
                        .catch(() => {
                           paddle.Checkout.open({
                              successCallback: this.showSuccess,
                              coupon: onSale ? 'BDJ2020' : null,
                              product: productId,
                           });
                        });
                  }
               }}
            />
         </React.Fragment>
      );
   }
}

import React from 'react';
import SimplePageSection from '../../containers/SaasModern/SimplePage';
import PricingSection from '../../containers/SaasModern/Pricing';
import {connect} from "react-redux";

const mapStateToProps = ({ paddleAffiliateId }) => {
   return { paddleAffiliateId };
};

class License extends React.Component {
   render() {
      const { paddleAffiliateId } = this.props;

      const content = (
      <>
         <h1>Purchase a PrintableCal License</h1>
         <p>
            PrintableCal provides 4 license types depending on your needs -
            Basic, Standard, Premium, and Ultimate. A license can be
            easily upgraded from one type to another, for only the cost of the
            difference.
         </p>
         <PricingSection showTitle={false} showLite={false} paddleAffiliateId={paddleAffiliateId} />
      </>
      );

      return (
         <SimplePageSection
            title="Purchase a PrintableCal License"
            description="PrintableCal generates printable calendars in Microsoft Office. A wide variety of free printable calendar templates are provided. Paid versions enable importing of data from Google Calendar, Outlook, Microsoft 365, iCloud, and many other sources."
            keywords="printable calendars, calendar templates, customize templates, overlay calendars, Excel calendar, Word calendar, generate calendar, date picker"
            content={content}
         />
      );
   }
}

export default connect(mapStateToProps)(License);
